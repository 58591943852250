.warning {
    display: flex;
    justify-content: center;
    border: red 1px solid;
    align-items: center;
    margin: 50px 10px;
    padding: 30px;
    background: rgba(255, 179, 179, 0.512);
    color: red;
    font-size: 25px;
}

.body {
    padding-top: 40vh;
}