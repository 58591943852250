.nav {
    /* width: 100vw; */
    height: 100%;
    margin: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: start;
    justify-content: start;
}

.nav .catTab {
    padding: 8px 20px !important;
    font-size: 20px !important;
    text-transform: capitalize !important;
}

.nav nav {
    display: flex;
    width: 100vw;
    align-items: start;
}

.nav nav ul li {
    position: relative;
}

.nav nav ul {
    display: flex;
}

.nav nav ul li button {
    text-transform: capitalize !important;
    padding: 21px 25px !important;
    color: #3bb77e !important;
    border-radius: 0px !important;
}

.nav nav ul li button a {
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.8) !important;
    font-size: 21px;
}

.nav .part3 .phNo svg {
    font-size: 45px !important;
    opacity: 0.8;
}

.nav .part3 .phNo h3 {
    font-size: 32px !important;
    line-height: 35px !important;
}

.nav .part3 .phNo p {
    font-size: 16px !important;
    opacity: 0.8;
}

.nav nav .dropdown_menu {
    position: absolute;
    top: 150%;
    left: 0px;
    width: 300px;
    height: auto;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 10px 0px;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
}

nav .dropdown_menu li {
    width: 100% !important;
    list-style: none;
    margin: 0px !important;
}

.nav nav .dropdown_menu li button {
    width: 100% !important;
    text-align: left !important;
    justify-content: flex-start !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    padding: 10px 20px !important;
    color: #3bb77e !important;
}

.nav nav .dropdown_menu li button a {
    font-weight: 400 !important;
}

nav .dropdown_menu li button:hover {
    background: #f1f1f1 !important;
}

.nav nav li:hover .dropdown_menu {
    top: 100%;
    opacity: 1;
    visibility: inherit;
}

.megaMenu {
    height: auto !important;
    padding: 40px 45px !important;
}

.megaMenu .col ul li {
    margin-bottom: 10px !important;
    display: block;
}

.megaMenu .col ul li a {
    color: rgba(0, 0, 0, 0.7);
    font-size: 20px;
    text-decoration: none;
}