.body {
    padding-top: 40vh;
}

.homeProducts {
    padding: 25px 0px;
}

.filterTab li {
    margin-left: 20px;
    position: relative;
}

.list-inline {
    display: flex;
}

.filterTab li a {
    color: #000;
    text-decoration: none;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    position: relative;
    font-weight: 600;
}

.filterTab li:hover a,
.filterTab li a.act {
    color: #3bb77e !important;
    transform: translateY(-10px);
}

.homeProducts .productRow {
    display: flex;
    padding: 30px 0px;
    margin: 0px -15px;
    flex-wrap: wrap;
}

.homeProducts .productRow.loading {
    opacity: 0.5;
    filter: blur(5px);
}

.homeProducts .productRow .item {
    width: 25%;
    padding: 15px 15px;
}

.homeProductWrapper .productRow .item {
    width: 20%;
}

.homeProductsRow2 .prodSlider .item {
    padding: 15px 5px;
    padding-right: 25px !important;
}


.topProductsSection {
    padding: 35px 0px;
}


.newsLetterSection {
    width: 100%;
    height: auto;
}

.newsLetterSection .box {
    background: url(../../assets/images/bg.png);
    width: 100%;
    height: auto;
    padding: 60px 80px;
    border-radius: 30px;
    overflow: hidden;
}

.newsLetterSection .box .info {
    width: 55%;
}

.newsLetterSection .box .info h2 {
    font-size: 60px;
    font-weight: 600;
    line-height: 70px;
    opacity: 0.8;
}

.newsLetterSection .box .info p {
    font-size: 22px;
    opacity: 0.8;
}

.newsLetterSection .box .img {
    width: 45%;
}

.newsLetterSection .box .img img {
    position: relative;
    top: 60px;
}

.newsLetterSection .box .newsLetterBanner {
    position: relative !important;
    left: 0px !important;
    bottom: inherit !important;
}